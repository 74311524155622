import React from 'react';
import { Auth } from 'aws-amplify';
import {Link} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import axios from 'axios';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {connect} from 'react-redux';
import {Dialog} from "primereact/dialog";
import {Messages} from 'primereact/messages';
import { Panel } from 'primereact/panel';
import {ProgressSpinner} from "primereact/progressspinner";

const NO_INPUT_LABEL = "no input";

class PricingResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasLocalPrice: false,
            searchResults: [],
            filteredSearchResults: [],
            searchFieldInput: '',
            displayAdminDialog: false,
            entry: null
        };
        
        // make sure that the currency is formatted accordingly for download!
        this.numberTemplate = this.numberTemplate.bind(this);
        this.currencyTemplate = this.currencyTemplate.bind(this);
        this.stringTemplate = this.stringTemplate.bind(this);
        this.differenceTemplate = this.differenceTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount() {
        if (this.props.searchInputs === 'undefined') {
            return;
        }

        let cloned = this.props.searchInputs.map(x => Object.assign({}, x));
        // the damage value needs to be converted into EUR, so the backend has the correct values
        if (this.props.selectedCurrency !== 'undefined') {
            cloned.forEach(elem => {
                elem.damage = elem.damage / this.props.selectedModifier;
                elem.localBid = elem.localBid / this.props.selectedModifier;
            });
        }

        const searchInputs = {
            country_code: this.props.countryCode,
            currency: this.props.selectedCurrency,
            exchange_rate: this.props.selectedModifier,
            search_inputs: cloned
        };

        this.setState({isLoading: true});

        // perform webservice call
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };
                axios.post(process.env.REACT_APP_IUCB_PRICING_ENDPOINT, searchInputs, axiosConfig)
                    .then(result => {
                        // array containing the models and details

                        // the backend calculates with EUR, so a conversion back into the original currency is required
                        // however, this conversion is done in the table, so do not convert it here
                        /*
                        result.data.search_results.forEach(elem => {
                            elem.damage = this.convertEuroToCurrency(elem.damage, this.props.selectedCurrency);
                            elem.localBid = this.convertEuroToCurrency(elem.localBid, this.props.selectedCurrency);
                        });
                        */

                        this.setState({
                            searchResults: result.data.search_results,
                            filteredSearchResults: result.data.search_results
                        });

                        let localprices = 0;
                        result.data.search_results.forEach(elem => {
                            if (elem.has_local_price === true) {
                                localprices++;
                            }
                        });

                        if (localprices > 0 ){
                            this.setState({hasLocalPrice: true});
                        }


                        this.setState({isLoading: false});
                    })
                    .catch(err => {
                        console.log(err);
                        this.messages.show({ severity: 'error', summary: 'Error', detail: err.message });
                        this.setState({isLoading: false});
                    });
            })
            .catch(err => {
                console.log(err);
                this.messages.show({ severity: 'error', summary: 'Login Failure', detail: err.message });
                this.setState({isLoading: false});
            });
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-search" className="p-button-success" style={{marginRight: '.5em'}} onClick={() => this.onDetailSelected(rowData)}></Button>
        </div>;
    }

    matchExact(r, str) {
        let match = str.match(r);
        return match && str === match[0];
    }

    handleFilterChange = () => {
        const value = this.state.searchFieldInput;

        if (value === '') {
            this.setState({filteredSearchResults: this.state.searchResults});
            return;
        }

        let number_re = /-?\d+(\.\d*)?/;
        let percent_re = /-?\d+(\.\d*)?%/;

        const filteredResults = [];
        if (this.matchExact(number_re, value)) {
            const compareValue = parseFloat(value);
           this.state.searchResults.forEach(element => {
               if (compareValue < ( element.diff_to_local * this.props.selectedModifier) ) {
                   filteredResults.push(element);
               }
           })
        } else if (this.matchExact(percent_re, value)) {
            let number = value.substring(0, value.length - 1);

            this.state.searchResults.forEach(element => {
                const compareValue = parseFloat(number) / 100 * element.zvk5 * this.props.selectedModifier;
                if (compareValue < ( element.diff_to_local * this.props.selectedModifier) ) {
                    filteredResults.push(element);
                }
            })
        }

        this.setState({filteredSearchResults: filteredResults});

    };


    currencyTemplate(rowData, column) {
        if (rowData[column.field] === '') {
            return <div style={{backgroundColor: '#ffd4cb'}}>{NO_INPUT_LABEL}</div>;
        } else {
            return <div>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: this.props.selectedCurrency }).format(rowData[column.field]  * this.props.selectedModifier)}</div>
        }
    }

    numberTemplate(rowData, column) {
        if (rowData[column.field] === '') {
            return <div style={{backgroundColor: '#ffd4cb'}}>{NO_INPUT_LABEL}</div>;
        } else {
            return <div>{new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(rowData[column.field])}</div>
        }
    }


    differenceTemplate(rowData, column) {
        if (rowData[column.field] > 0) {
            if (rowData['notification'] !== '') {
                return <div style={{display:"flex"}}>
                    <span style={{backgroundColor: '#45ff90'}}>
                        {new Intl.NumberFormat('de-DE', {style: 'currency', currency: this.props.selectedCurrency }).format(rowData[column.field] * this.props.selectedModifier)}
                    </span>
                        <button type="text"
                            style={{"fontSize":"0.7rem"}}
                            className="js-tooltip  c-icon  c-icon--[control-info] c-icon--xsmall c-icon--border"
                            title={rowData['notification']}/>
                </div>;
            } else {
                return <div style={{backgroundColor: '#45ff90'}}>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: this.props.selectedCurrency }).format(rowData[column.field] * this.props.selectedModifier)}</div>;
            }
        } else {
            if (rowData['notification'] !== '') {
                return <div style={{display:"flex"}}>
                <span style={{backgroundColor: '#ffd4cb'}}>
                    {new Intl.NumberFormat('de-DE', {style: 'currency', currency: this.props.selectedCurrency }).format(rowData[column.field] * this.props.selectedModifier)}
                </span>
                    <button type="text"
                            style={{"fontSize":"0.7rem"}}
                            className="js-tooltip  c-icon  c-icon--[control-info] c-icon--xsmall c-icon--border"
                            title={rowData['notification']}></button>
                </div>;
            } else {
                return <div style={{backgroundColor: '#ffd4cb'}}>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: this.props.selectedCurrency }).format(rowData[column.field] * this.props.selectedModifier)}</div>;
            }

        }
    }

    stringTemplate(rowData, column) {
        if (rowData[column.field] === '') {
            return <div style={{backgroundColor: '#ffd4cb'}}>{NO_INPUT_LABEL}</div>;
        } else {
            return <div>{rowData[column.field]}</div>
        }
    }

    toEuro(price, exchangeRate) {
        const cleanedPrice = Number.parseFloat(price.replace(",", "."));
        const cleanedExchangeRage = Number.parseFloat(exchangeRate.replace(",", "."));

        return cleanedPrice / cleanedExchangeRage;
    }

    toOtherCurrency(price, exchangeRate) {
        return price * exchangeRate;
    }

    formatRule = (elem) => {
        return elem.selector + " " + elem.land + " " + elem.marke + " " + elem.modell + " " + elem.von_km + " " + elem.bis_km +
            " " + elem.von_alter + " " + elem.bis_alter + " " + elem.percent;
    };

    exportAussteuerungTable = () => {

        let csv = '\ufeff';

        //file for "Aussteuerung" des GWC structure is given and fixed
        csv += '"Offer Date";';
        csv += '"Personal Ref";';
        csv += '"VIN";';
        csv += '"Number Plate";';
        csv += '"Brand";';

        csv += '"Model";';
        csv += '"Registration";';

        csv += '"1. Registration Date";';
        csv += '"Mileage";';

        csv += '"Local Damage";';
        csv += '"Currency";';

        csv += '"Location";';

        csv += '"Taxation (S/D)";';
        csv += '"Start Price for Local Auction";';
        csv += '"Residual Value";';




        // admin IUCB Offer-List 11-04-2019 1400
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        const filename = 'Offer-List_' + this.props.countryCode + '_' + dd + '_' + mm + '_' + yyyy + '.csv';


        //body
        this.state.filteredSearchResults.forEach((record, i) => {
            csv += '\n';

            csv += '"' + dd + '.' + mm + '.' + yyyy + '";';
            csv += '" ";';
            csv += '"' + record.vin + '";';
            csv += '" ";';

            csv += '"' + record.brand + '";';
            // csv += '"' + record.mbv + '";';
            csv += '"' + record.model + '";';
            csv += '"CAR";';
            csv += '"' + record.registration_date + '";';

            csv += '"' + record.mileage + '";';
            // csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.damage * this.props.selectedModifier) + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.damage * this.props.selectedModifier) + '";';
            csv += '"' + this.props.selectedCurrency + '";';
            csv += '" ";';
            csv += '"Standard";';
            csv += '" ";';
            csv += '" ";';

        });

        let blob = new Blob([csv],{
            type: 'text/csv;charset=utf-8;'
        });



        if(window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        }
        else {
            let link = document.createElement("a");
            link.style.display = 'none';
            document.body.appendChild(link);
            if(link.download !== undefined) {
                link.setAttribute('href', URL.createObjectURL(blob));
                link.setAttribute('download', filename);
                link.click();
            }
            else {
                csv = 'data:text/csv;charset=utf-8,' + csv;
                window.open(encodeURI(csv));
            }
            document.body.removeChild(link);
        }
    };

    onDetailSelected = (e) => {
        if(this.props.isAdmin) {
            this.setState({
                entry: e,
                displayAdminDialog: true
            });
        }
    };

    exportTable = () => {
        // this.dt.exportCSV();

        let csv = '\ufeff';

        csv += '"ID";';
        csv += '"VIN";';
        csv += '"Mileage";';
        csv += '"Damage";';
        csv += '"Registration Date";';
        csv += '"MBV";';

        csv += '"Brand";';
        csv += '"Model";';
        csv += '"Engine Type";';
        csv += '"Color Code";';

        csv += '"Listprice";';
        csv += '"Local Bid";';
        csv += '"IUCB Price";';
        if (this.state.hasLocalPrice) {
            csv += '"Local Price";';
        }
        csv += '"Difference";';
        csv += '"Currency";';
        csv += '"Notification"';


        //body
        this.state.filteredSearchResults.forEach((record, i) => {
            csv += '\n';
            csv += '"' + record.input_number + '";';
            csv += '"' + record.vin + '";';
            csv += '"' + record.mileage + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.damage * this.props.selectedModifier) + '";';
            csv += '"' + record.registration_date + '";';
            csv += '"' + record.mbv + '";';

            csv += '"' + record.brand + '";';
            csv += '"' + record.model + '";';
            csv += '"' + record.engine_type + '";';
            csv += '"' + record.color_code + '";';

            csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.listprice_total_net * this.props.selectedModifier) + '";';
            if (typeof record.local_bid == 'number') {
                csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.local_bid * this.props.selectedModifier) + '";';
            } else {
                csv += '"' + record.local_bid + '";';
            }

            csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.zvk5 * this.props.selectedModifier) + '";';

            if (this.state.hasLocalPrice) {
                csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.zvk5_local * this.props.selectedModifier) + '";';
            }

            csv += '"' + new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(record.diff_to_local * this.props.selectedModifier) + '";';
            csv += '"' + this.props.selectedCurrency + '";';
            csv += '"' + record.notification + '"';
        });

        let blob = new Blob([csv],{
            type: 'text/csv;charset=utf-8;'
        });

        // admin IUCB Price Indication Results 11-04-2019 1401
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        const hours = today.getHours();
        const minutes = today.getMinutes();

        const filename = 'Price-Indication-Results_' + this.props.countryCode + '_' + dd + '_' + mm + '_' + yyyy + '_' + hours + '_' + minutes + '.csv';

        if(window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        }
        else {
            let link = document.createElement("a");
            link.style.display = 'none';
            document.body.appendChild(link);
            if(link.download !== undefined) {
                link.setAttribute('href', URL.createObjectURL(blob));
                link.setAttribute('download', filename);
                link.click();
            }
            else {
                csv = 'data:text/csv;charset=utf-8,' + csv;
                window.open(encodeURI(csv));
            }
            document.body.removeChild(link);
        }
    };

    render() {
        let header = <div style={{'textAlign':'left'}}>
            <div className="p-col-12 p-md-4">
                <div className="p-inputgroup">
                    <InputText placeholder="Enter an amount or percent value (e.g. 5%)" type="search" size="100"
                               onChange={(e) => {this.setState({searchFieldInput: e.target.value})}}/>
                    <Button label="Search" onClick={() => this.handleFilterChange()}/>
                </div>
            </div>
        </div>;


        let datatable = <DataTable value={this.state.filteredSearchResults} csvSeparator={";"} ref={(el) => { this.dt = el; }}
                                   header={header}
                                   globalFilter={this.state.globalFilter}
                                   scrollable={true}>
            <Column key={'input_number'} field={'input_number'} style={{width: "50px"}} sortable={true}>
                                        <span className="js-tooltip c-tooltip"
                                              title="IDs are used to identify VINs and Inputs">ID</span>
            </Column>
            <Column key={'vin'} field={'vin'} header={'VIN'} style={{width: "200px"}} />
            <Column key={'mileage'} field={'mileage'} header={'Mileage (KM)'} style={{width: "80px"}} body={this.numberTemplate} />
            <Column key={'damage'} field={'damage'} header={'Damage'} style={{width: "100px"}} body={this.currencyTemplate} />

            <Column key={'registration_date'} field={'registration_date'} header={'First Registration Date'} style={{width: "100px"}} body={this.stringTemplate} />
            <Column key={'mbv'} field={'mbv'} header={'MBV Code'} style={{width: "80px"}}  body={this.stringTemplate} />
            <Column key={'brand'} field={'brand'} header={'Brand'} style={{width: "100px"}} body={this.stringTemplate} />
            <Column key={'model'} field={'model'} header={'Model'} style={{width: "100px"}} />

            <Column key={'engine_type'} field={'engine_type'} header={'Engine Type'} style={{width: "80px"}} />

            <Column key={'listprice_total_net'} field={'listprice_total_net'} header={'Listprice'} style={{width: "140px"}} body={this.currencyTemplate} />
            <Column key={'local_bid'} field={'local_bid'} header={'Highest Local Bid'} style={{width: "140px"}} body={this.currencyTemplate} />

            <Column key={'zvk5'} field={'zvk5'} header={'IUCB Price'} style={{width: "140px"}} body={this.currencyTemplate} />
            <Column key={'diff_to_local'} field={'diff_to_local'} header={'Difference'} style={{width: "140px"}} body={this.differenceTemplate} sortable={true}/>
        </DataTable>;

        if (this.props.isAdmin) {
            datatable = <DataTable value={this.state.filteredSearchResults} csvSeparator={";"} ref={(el) => { this.dt = el; }}
                                       header={header}
                                       globalFilter={this.state.globalFilter}
                                       scrollable={true}>
                <Column key={'input_number'} field={'input_number'} style={{width: "50px"}} sortable={true}>
                                        <span className="js-tooltip c-tooltip"
                                              title="IDs are used to identify VINs and Inputs">ID</span>
                </Column>
                <Column key={'vin'} field={'vin'} header={'VIN'} style={{width: "200px"}} />
                <Column key={'mileage'} field={'mileage'} header={'Mileage (KM)'} style={{width: "80px"}} body={this.numberTemplate} />
                <Column key={'damage'} field={'damage'} header={'Damage'} style={{width: "100px"}} body={this.currencyTemplate} />

                <Column key={'registration_date'} field={'registration_date'} header={'First Registration Date'} style={{width: "100px"}} body={this.stringTemplate} />
                <Column key={'mbv'} field={'mbv'} header={'MBV Code'} style={{width: "80px"}}  body={this.stringTemplate} />
                <Column key={'brand'} field={'brand'} header={'Brand'} style={{width: "100px"}} body={this.stringTemplate} />
                <Column key={'model'} field={'model'} header={'Model'} style={{width: "100px"}} />

                <Column key={'engine_type'} field={'engine_type'} header={'Engine Type'} style={{width: "80px"}} />

                <Column key={'listprice_total_net'} field={'listprice_total_net'} header={'Listprice'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'local_bid'} field={'local_bid'} header={'Highest Local Bid'} style={{width: "140px"}} body={this.currencyTemplate} />

                <Column key={'zvk5'} field={'zvk5'} header={'IUCB Price'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'diff_to_local'} field={'diff_to_local'} header={'Difference'} style={{width: "140px"}} body={this.differenceTemplate} sortable={true}/>
                <Column body={this.actionTemplate} style={{textAlign:'center', width: '50px'}}/>
            </DataTable>;
        }



        // is there a local price?
        if (this.state.hasLocalPrice && !this.props.isAdmin) {
            datatable = <DataTable value={this.state.filteredSearchResults} csvSeparator={";"} ref={(el) => { this.dt = el; }}
                                   header={header}
                                   globalFilter={this.state.globalFilter}
                                   scrollable={true}>
                <Column key={'input_number'} field={'input_number'} style={{width: "50px"}} sortable={true}>
                                        <span className="js-tooltip c-tooltip"
                                              title="IDs are used to identify VINs and Inputs">ID</span>
                </Column>
                <Column key={'vin'} field={'vin'} header={'VIN'} style={{width: "200px"}} />
                <Column key={'mileage'} field={'mileage'} header={'Mileage (KM)'} style={{width: "80px"}} body={this.numberTemplate} />
                <Column key={'damage'} field={'damage'} header={'Damage'} style={{width: "100px"}} body={this.currencyTemplate} />

                <Column key={'registration_date'} field={'registration_date'} header={'First Registration Date'} style={{width: "100px"}} body={this.stringTemplate} />
                <Column key={'mbv'} field={'mbv'} header={'MBV Code'} style={{width: "80px"}}  body={this.stringTemplate} />
                <Column key={'brand'} field={'brand'} header={'Brand'} style={{width: "100px"}} body={this.stringTemplate} />
                <Column key={'model'} field={'model'} header={'Model'} style={{width: "100px"}} />

                <Column key={'engine_type'} field={'engine_type'} header={'Engine Type'} style={{width: "80px"}} />

                <Column key={'listprice_total_net'} field={'listprice_total_net'} header={'Listprice'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'local_bid'} field={'local_bid'} header={'Highest Local Bid'} style={{width: "140px"}} body={this.currencyTemplate} />

                <Column key={'zvk5'} field={'zvk5'} header={'IUCB Price'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'zvk5_local'} field={'zvk5_local'} header={'Local Price'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'diff_to_local'} field={'diff_to_local'} header={'Difference'} style={{width: "140px"}} body={this.differenceTemplate} sortable={true}/>
            </DataTable>;
        }

        if (this.state.hasLocalPrice && this.props.isAdmin) {
            datatable = <DataTable value={this.state.filteredSearchResults} csvSeparator={";"} ref={(el) => { this.dt = el; }}
                                   header={header}
                                   globalFilter={this.state.globalFilter}
                                   scrollable={true}>
                <Column key={'input_number'} field={'input_number'} style={{width: "100px"}} sortable={true}>
                                        <span className="js-tooltip c-tooltip"
                                              title="IDs are used to identify VINs and Inputs">ID</span>
                </Column>
                <Column key={'vin'} field={'vin'} header={'VIN'} style={{width: "180px"}} />
                <Column key={'mileage'} field={'mileage'} header={'Mileage (KM)'} style={{width: "80px"}} body={this.numberTemplate} />
                <Column key={'damage'} field={'damage'} header={'Damage'} style={{width: "100px"}} body={this.currencyTemplate} />

                <Column key={'registration_date'} field={'registration_date'} header={'First Registration Date'} style={{width: "100px"}} body={this.stringTemplate} />
                <Column key={'mbv'} field={'mbv'} header={'MBV Code'} style={{width: "80px"}}  body={this.stringTemplate} />
                <Column key={'brand'} field={'brand'} header={'Brand'} style={{width: "100px"}} body={this.stringTemplate} />
                <Column key={'model'} field={'model'} header={'Model'} style={{width: "100px"}} />

                <Column key={'engine_type'} field={'engine_type'} header={'Engine Type'} style={{width: "80px"}} />

                <Column key={'listprice_total_net'} field={'listprice_total_net'} header={'Listprice'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'local_bid'} field={'local_bid'} header={'Highest Local Bid'} style={{width: "140px"}} body={this.currencyTemplate} />

                <Column key={'zvk5'} field={'zvk5'} header={'IUCB Price'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'zvk5_local'} field={'zvk5_local'} header={'Local Price'} style={{width: "140px"}} body={this.currencyTemplate} />
                <Column key={'diff_to_local'} field={'diff_to_local'} header={'Difference'} style={{width: "140px"}} body={this.differenceTemplate} sortable={true}/>
                <Column body={this.actionTemplate} style={{textAlign:'center', width: '50px'}}/>
            </DataTable>;
        }



        return (
            <div>
                <nav className="c-stepper-nav o-page-wrap">
                    <ol className="c-stepper-nav__list">
                        <li className="c-stepper-nav__item  is-active">
                            <Link className="c-stepper-nav__link" to="/">Welcome!</Link>
                        </li>
                        <li className="c-stepper-nav__item  is-active">
                            <Link className="c-stepper-nav__link" to="/">Login</Link>
                        </li>
                        <li className="c-stepper-nav__item is-active">
                            <Link className="c-stepper-nav__link" to="/pricing">Enter your data</Link>
                        </li>
                        <li className="c-stepper-nav__item is-current">
                            <Link className="c-stepper-nav__link" to="/result">Calculation and Results</Link>
                        </li>
                    </ol>
                </nav>

                <main className="o-page-wrap">
                    <section className="o-content-section">
                        <Messages ref={(el) => this.messages = el}></Messages>

                        {this.props.isAdmin ?
                        <div>
                            <h2>Admin Mode</h2>
                            <p>Performing search for country {this.props.countryCode}.</p>
                        </div>
                        : null}
                        {this.state.isLoading ?
                            <div>
                                <ProgressSpinner style={{width: '50px', height: '50px'}}/>
                                <p>Loading results...</p>
                            </div>
                            :
                            this.state.searchResults ?
                                <div>
                                    <h2>Results in {this.props.selectedCurrency}</h2>

                                    <Button type="button" style={{marginBottom: '3px'}} icon="pi pi-fw pi-download" label="Download as Offerlist" onClick={this.exportAussteuerungTable} />
                                    <Button type="button" style={{marginBottom: '3px', marginLeft: '5px'}} icon="pi pi-fw pi-download"  label="Download Results" onClick={this.exportTable} />

                                    <br />
                                    {datatable}

                                    <Button type="button" icon="pi pi-fw pi-arrow-left" onClick={() => window.location = "#/pricing"} label="Go Back" />
                                </div>
                                :
                                <div>
                                    <p>No data found.</p>
                                    <Button type="button" icon="pi pi-fw pi-arrow-left" onClick={() => window.location = "#/pricing"} label="Go Back" />
                                </div>
                        }


                        <Dialog visible={this.state.displayAdminDialog} style={{ width: '70vw' }} header="Computation Details" modal={true} onHide={() => this.setState({displayAdminDialog: false})}
                            contentStyle={{maxHeight: '500px'}}>
                            {
                                this.state.entry &&

                                <div className="p-grid p-fluid">
                                    <div className="p-col-12" style={{padding: '0.5rem'}}>
                                        <Panel header="Startwertkurve">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-6" >SWK: {this.state.entry.swk_id}</div>
                                                <div className="p-col-6" >Abschlag Wert: {this.state.entry.abschlag_wert}</div>
                                                <div className="p-col-6" >Fahrzeugalter (M): {this.state.entry.car_age}</div>
                                            </div>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12" style={{padding: '0.5rem'}}>
                                        <Panel header="Derivat">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12" >Wert Derivat: {this.state.entry.wert_derivat}</div>
                                                <div className="p-col-6" >x11: {this.state.entry.x11}</div>
                                                <div className="p-col-6" >x12: {this.state.entry.x12}</div>
                                                <div className="p-col-6" >x21: {this.state.entry.x21}</div>
                                                <div className="p-col-6" >x22: {this.state.entry.x22}</div>
                                            </div>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12" style={{padding: '0.5rem'}}>
                                        <Panel header="Individuelle Abschläge">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-6" >SWK Adjustment: {this.state.entry.swk_adjustment}</div>
                                                <div className="p-col-6" >Schadentabelle: {this.state.entry.schadentabelle_damage}</div>
                                                <div className="p-col-6" >Schadenstaffel: {this.state.entry.schadenstaffel_damage}</div>
                                                <div className="p-col-6" >Farbabschlag: {this.state.entry.color_adjustment}</div>
                                            </div>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12" style={{padding: '0.5rem'}}>
                                        <Panel header="Internationale Regeln">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-6" >Regelwerk Prozente: {this.state.entry.percentage_adjustment_rules}</div>
                                                {
                                                    this.state.entry.rules ?
                                                        this.state.entry.rules.map((elem, i) => {
                                                        return (<div key={i} className="p-col-12">Regel: {this.formatRule(elem)}</div>)
                                                    }) : null // or any fallback component/UI
                                                }
                                            </div>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12" style={{padding: '0.5rem'}}>
                                        <Panel header="Einzelauflistung Preise">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12 p-md-6 p-lg-3" >ZVK 0: {this.state.entry.zvk0}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >ZVK 1: {this.state.entry.zvk1}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >ZVK 2: {this.state.entry.zvk2}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >ZVK 4: {this.state.entry.zvk4}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >ZVK 5: {this.state.entry.zvk5}</div>
                                            </div>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12" style={{padding: '0.5rem'}}>
                                        <Panel header="Zusatzkosten">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12 p-md-6 p-lg-3" >Transportkosten: {this.state.entry.transportkosten}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >Gemeinkosten: {this.state.entry.gemeinkosten}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >Gutachtenkosten: {this.state.entry.gutachtenkosten}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >Sonstiges: {this.state.entry.sonstiges}</div>
                                            </div>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12" style={{padding: '0.5rem'}}>
                                        {this.state.searchResults ?
                                        <Panel header="Lokale Anpassungen">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12 p-md-6 p-lg-3" >ZVK 4 lokal: {this.state.entry.zvk4_local}</div>
                                                <div className="p-col-12 p-md-6 p-lg-3" >ZVK 5 lokal: {this.state.entry.zvk5_local}</div>
                                                <div className="p-col-6" >Regelwerk Prozente: {this.state.entry.percentage_adjustment_rules_local}</div>
                                                {
                                                    this.state.entry.local_rules ?
                                                    this.state.entry.local_rules.map((elem, i) => {
                                                        return (<div key={i} className="p-col-12">Regel: {this.formatRule(elem)}</div>)
                                                    }) : null
                                                }
                                            </div>
                                        </Panel>
                                        : null }
                                    </div>

                                </div>
                            }
                        </Dialog>


                    </section>
                </main>

            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        searchInputs: state.searchInputs,
        countryCode: state.countryCode,
        selectedCurrency: state.selectedCurrency,
        selectedModifier: state.selectedModifier,
        isAdmin: state.isAdmin
    }
};

export default connect(mapStateToProps)(PricingResult);
