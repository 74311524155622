import React from 'react'
import {
    Link
} from 'react-router-dom';
import {connect} from "react-redux";
import { Menubar } from 'primereact/menubar';
import {Button} from "primereact/button";

class Header extends React.Component {

    componentDidMount() {
        window.bronson.ComponentRegister.initAll();
    }


    getUsername = () => {
        let username = '';
        if (this.props.user) {
            username = this.props.user.username;

            if (this.props.isAdmin) {
                username += ' (Admin)';
            }
        }

        return username;
    };

    getExtension = () => {
      if (this.props.countryCode !== "") {
          if (this.props.isExtendedUser) {
              return '(' + this.props.countryCode + ', +)';
          } else {
              // select a country flag
              return '(' + this.props.countryCode + ')';
          }
      } else {
          if (this.props.isExtendedUser) {
              return "(+)";
          } else {
              return "";
          }

      }
    };

    onLogout = () => {
        this.props.onLogout();
    };

    render() {

        let items = [];
        if (this.props.user && !this.props.isAdmin) {
            items = [
                {
                    label:'IUCB Analytics',
                    items:[
                        {
                            label:'Start Analysis',
                            icon:'pi pi-fw pi-chart-line'
                        }
                    ]
                }
            ];
        } else if (this.props.user && this.props.isAdmin) {
            items = [
                {
                    label:'IUCB Analytics',
                    items:[
                        {
                            label:'Start Analysis',
                            icon:'pi pi-fw pi-chart-line',
                            command: ()=>{ window.location = "#/pricing"}
                        }
                    ]
                },
                {
                    label:'Admin',
                    items:[
                        {
                            label:'Update Fahrzeugliste',
                            command: ()=>{ window.location = "#/car_update"}
                        },
                        {
                            label:'Update Startwertkurve',
                            command: ()=>{ window.location = "#/swk_update"}
                        },
                        {
                            label:'Update Abschlagtabelle',
                            command: ()=>{ window.location = "#/abs_update"}
                        },
                        {
                            label:'Update Schadenstaffel',
                            command: ()=>{ window.location = "#/staffel_update"}
                        },
                        {
                            label:'Update Schadentabelle',
                            command: ()=>{ window.location = "#/schaden_update"}
                        },
                        {
                            label:'Update Farbabschläge',
                            command: ()=>{ window.location = "#/farb_update"}
                        },
                        {
                            label:'Update Regelwerk',
                            command: ()=>{ window.location = "#/regel_update"}
                        },
                        {
                            label:'Update Meldungen',
                            command: ()=>{ window.location = "#/meldungen_update"}
                        },

                        {
                            label:'Update Länderkosten',
                            command: ()=>{ window.location = "#/laender_update"}
                        },
                        {
                            label:'Update Methodenwahl',
                            command: ()=>{ window.location = "#/method_update"}
                        },
                        {
                            label:'Logs',
                            command: ()=>{ window.location = "#/logs"}
                        },
                    ]
                }
            ];
        }

        return (
        <header className="c-header__wrapper">
            <div className="c-header  c-header--static">
                <div className="c-header__content-wrapper" style={{alignItems: 'initial'}}>

                    <div className="c-vertical-bar__logo">
                        <Link className="c-logo  c-logo--has-image" to="/" aria-label="Logo">
                            <img src="logo-small-screen.svg" alt="Logo" className="c-logo__image" />
                        </Link>

                    </div>

                    { this.props.user ?
                        <div className="card" style={{width: '100%'}}>
                            <Menubar style={{height: '48px'}}
                                model={items}
                                end={<Button label="Logout" icon="pi pi-power-off" onClick={() => this.onLogout()}/>}
                            />
                        </div>
                    : null }
                </div>
            </div>
        </header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countryCode: state.countryCode,
        user: state.user,
        userGroups: state.userGroups,
        isAdmin: state.isAdmin,
        isExtendedUser: state.isExtendedUser
    };
};

export default connect(mapStateToProps)(Header);
