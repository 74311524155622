// thank you IE11 for making my life horrible finding a bug in regex string split...
// downgrade core js to 3.5.0 there is a regex bug in 3.6.x
// https://stackoverflow.com/questions/63731909/angular-polyfill-problem-ie11-core-js-v3-6-5-method-es-string-split-js-fails
// https://github.com/zloirock/core-js/issues/751
import 'core-js/stable/array';
import 'core-js/stable/date';
import 'core-js/stable/function';
import 'core-js/stable/map';
import 'core-js/stable/math';
import 'core-js/stable/number';
import 'core-js/stable/object';
import 'core-js/stable/parse-float';
import 'core-js/stable/parse-int';
// import 'core-js/stable/regexp';
import 'core-js/stable/set';
// import 'core-js/stable/string';
import 'core-js/stable/symbol';
import 'core-js/stable/weak-map';
import 'regenerator-runtime/runtime';
import "isomorphic-fetch"; //npm install --save isomorphic fetch
import 'es6-promise'; //npm install --save es6-promise

import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux';
import reducer from './store/reducer';
import {Provider} from 'react-redux';

import packageJson from '../package.json';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify'

const store = createStore(reducer);

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    }
});

ReactDOM.render(<Provider store={store}><App version={packageJson.version} /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
