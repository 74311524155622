import React from 'react'
import {Auth} from "aws-amplify";
import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Messages} from "primereact/messages";
import {Dropdown} from "primereact/dropdown";


class UpdateStartwertkurve extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            selectedSWK: 1,
            curves: [],
            entries: [],
            versions: [],
            isFileUploaded: false,
            dataTableUploadInProgress: false,
            dataTableUploadProgress: 0.0,
            selectedEntry: null,
            displayDialog: false,
            isLoading: false
        };

        this.numberTemplate = this.numberTemplate.bind(this);
    }

    numberTemplate(rowData, column) {
        if (rowData[column.field] === '') {
            return <div style={{backgroundColor: '#ffd4cb'}}></div>;
        } else {
            return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(rowData[column.field])
        }
    }

    componentDidMount() {
        //get the current values
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };
                axios.get(process.env.REACT_APP_IUCB_SWK_ENDPOINT, axiosConfig)
                    .then(result => {
                        if (result.data && result.data.length > 0) {
                            this.setState({curves: result.data, entries: result.data[0].values});
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });

                axios.get(process.env.REACT_APP_IUCB_SWK_VERSIONS_ENDPOINT, axiosConfig)
                    .then(result => {
                        console.log('requesting');
                        this.setState({versions: result.data});
                    })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => {
                console.log(err);
            });

    }

    onFileChange = (files) => {
        const selectedFile = files[0];
        if (!selectedFile) {
            return;
        }
        //upload to endpoint
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };
                const params = {
                    file_identifier: 'startwertkurve',
                    swk_id: this.state.selectedSWK
                };

                //retrive pre-signed URL
                axios.post(process.env.REACT_APP_IUCB_UPLOAD_ENDPOINT, params, axiosConfig)
                    .then(result => {
                        const url = result.data.upload_url;
                        // after receiving the signed URL an upload via put is possible
                        const axiosUploadConfig = {
                            headers: {'content-disposition': 'Attachment', 'Content-Type': selectedFile.type},
                            onUploadProgress: (progressEvent) => {
                                const totalLength = progressEvent.lengthComputable ? progressEvent.total : selectedFile.size;
                                if (totalLength !== null) {
                                    this.setState({dataTableUploadProgress: Math.round((progressEvent.loaded * 100) / totalLength)});
                                }
                            }
                        };
                        axios.put(url, selectedFile, axiosUploadConfig)
                            .then(result => {
                                this.messages.show({severity: 'success', summary: 'Success', detail: 'File is completely uploaded!'});

                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0,
                                    isFileUploaded: true
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0
                                });
                                this.messages.show({severity: 'error', summary: 'Upload Error', detail: err.message});
                            });
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch (err => {
                console.log(err);
            });
    };

    onSWKChange = (event) => {
        const swkId = parseInt(event.target.value);
        this.setState({selectedSWK: swkId});

        this.state.curves.forEach(curve => {
            if (curve.swk_id === swkId) {
                this.setState({entries: curve.values});
            }
        });

    };

    exportTable = () => {
        // this.dt.exportCSV();

        let csv = '\ufeff';

        csv += '"km/jahr";';
        csv += '"0";';
        csv += '"6";';
        csv += '"12";';
        csv += '"18";';
        csv += '"24";';

        csv += '"30";';
        csv += '"36";';
        csv += '"42";';
        csv += '"48";';

        csv += '"54";';
        csv += '"60";';

        //body
        this.state.entries.forEach((record, i) => {
            csv += '\n';
            csv += record.km_value + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_0) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_6) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_12) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_18) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_24) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_30) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_36) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_42) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_48) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_54) + ';';
            csv += new Intl.NumberFormat('de-DE', {maximumFractionDigits: 20 }).format(record.age_value_60);
        });

        let blob = new Blob([csv],{
            type: 'text/csv;charset=utf-8;'
        });

        if(window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, 'swk' + this.state.selectedSWK + '.csv');
        }
        else {
            let link = document.createElement("a");
            link.style.display = 'none';
            document.body.appendChild(link);
            if(link.download !== undefined) {
                link.setAttribute('href', URL.createObjectURL(blob));
                link.setAttribute('download', 'swk' + this.state.selectedSWK + '.csv');
                link.click();
            }
            else {
                csv = 'data:text/csv;charset=utf-8,' + csv;
                window.open(encodeURI(csv));
            }
            document.body.removeChild(link);
        }
    };


    render () {
        const currencySelectItems = [
            {label: '1', value: 1},
            {label: '2', value: 2},
            {label: '3', value: 3},
            {label: '4', value: 4},
            {label: '5', value: 5},
            {label: '11', value: 11},
            {label: '21', value: 21},
            {label: '22', value: 22},
        ];


        return (
            <main className="o-page-wrap">
                <div>
                    <h1>Value Curves (SWK)</h1>
                    <div className="o-fieldset__row">
                        <div className="o-layout">
                            <div className="o-layout__item  u-1/2  u-1/1@xs">
                                <label htmlFor="select_currency">Please select a curve:</label>
                                <div className="c-input">
                                    <Dropdown value={this.props.selectedSWK} options={currencySelectItems} onChange={(e) => this.onSWKChange(e)} placeholder="Select a Curve"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>The values are rounded to two numbers for better readability. You can download the curves as CSV.</p>

                    <button type="button" style={{marginBottom: '3px'}} className="c-btn  c-btn--icon" onClick={this.exportTable}>
                        <span className="c-btn__text">Export Table</span>
                        <i className="c-btn__icon  c-icon  c-icon--[control-download]"></i>
                    </button>

                    <DataTable value={this.state.entries} style={{width: '100%'}}
                               ref={(el) => { this.dt = el; }} csvSeparator=";"
                               rows={20} rowsPerPageOptions={[20,50,100]}>
                        <Column field="km_value" header="km/jahr" />
                        <Column field="age_value_0" header="0"  body={this.numberTemplate}/>
                        <Column field="age_value_6" header="6"  body={this.numberTemplate}/>
                        <Column field="age_value_12" header="12"  body={this.numberTemplate}/>
                        <Column field="age_value_18" header="18"  body={this.numberTemplate}/>
                        <Column field="age_value_24" header="24"  body={this.numberTemplate}/>
                        <Column field="age_value_30" header="30"  body={this.numberTemplate}/>
                        <Column field="age_value_36" header="36"  body={this.numberTemplate}/>
                        <Column field="age_value_42" header="42"  body={this.numberTemplate}/>
                        <Column field="age_value_48" header="48"  body={this.numberTemplate}/>
                        <Column field="age_value_54" header="54"  body={this.numberTemplate}/>
                        <Column field="age_value_60" header="60"  body={this.numberTemplate}/>
                    </DataTable>
                    <br />

                    <Messages ref={(el) => this.messages = el}></Messages>

                    <h2>Upload Curve</h2>
                    <p>If you upload a new version, the selected curve ID above will be overwritten. Please wait some seconds and then refresh the page for the values to update.</p>
                    <label htmlFor="file-upload" className="c-upload">
                        <input className="c-upload__input" type="file" id="file-upload" onChange={ (e) => this.onFileChange(e.target.files) } />

                        <p className="c-upload__description">
                            <span>Click on this container for file uploads</span>
                            <span>or</span>
                        </p>

                        <div className="c-upload__actions">
                            <label className="c-upload__button c-btn" htmlFor="file-upload">
                                <span>click here</span>
                            </label>
                        </div>
                    </label>
                    <h2>Versions</h2>
                    <DataTable value={this.state.versions} style={{width: '100%'}}
                               paginator={true}
                               rows={20} rowsPerPageOptions={[20,50,100]}>
                        <Column field="filename" header="Filename" style={{width:'100px'}} />
                        <Column field="content_length" header="File Size (Bytes)" style={{width:'100px'}} />
                        <Column field="last_modified" header="Last Modified" style={{width:'100px'}} />
                    </DataTable>
                </div>
            </main>
        )
    }
}
export default UpdateStartwertkurve;
