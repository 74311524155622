import React from 'react';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from 'primereact/dropdown';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from 'primereact/inputtextarea';
import {Messages} from "primereact/messages";
import {Toolbar} from "primereact/toolbar";
import { ProgressSpinner } from 'primereact/progressspinner';
import { connect } from 'react-redux';
import {
    Link
} from 'react-router-dom';

class PricingSearch extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            vinInput: '',
            mileageInput: 0,
            damageInput: 0,
            localBidInput: 0,
            registrationDateInput: '',
            excelInput: '',
            isLoading: false
        };

        this.vinEditor = this.vinEditor.bind(this);
        this.mileageEditor = this.mileageEditor.bind(this);
        this.damageEditor = this.damageEditor.bind(this);
        this.localbidEditor = this.localbidEditor.bind(this);
        this.registrationEditor = this.registrationEditor.bind(this);
        this.requiredValidator = this.requiredValidator.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
    }

    componentDidMount() {
        // this.props.onSetCountryCode('ESP');
    }

    addNew = () => {
        const newInput = {
            inputNumber: this.props.searchInputs.length,
            vin: '',
            mileage: '0',
            damage: '0',
            localBid: '0',
            registrationDate: '01.01.2015',
            mbv: '',
            brand: '',
            colorCode: '',
            listprice: 0
        };

        /*
        this.setState({
            searchInputs:  update(this.state.searchInputs, { $push: [newInput] })
        });
        */
        this.props.onAddSearchInputs(newInput);
    };

    deleteAll = () => {
      // this.setState( {searchInputs: []});
      this.props.onDeleteSearchInputs();
    };

    onEditorValueChange(props, value) {
        let updatedEntries = [...props.value];
        updatedEntries[props.rowIndex][props.field] = value;
        this.setState({searchInputs: updatedEntries});
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    vinEditor(props) {
        return this.inputTextEditor(props, 'vin');
    }

    mileageEditor(props) {
        return this.inputTextEditor(props, 'mileage');
    }

    damageEditor(props) {
        return this.inputTextEditor(props, 'damage');
    }

    localbidEditor(props) {
        return this.inputTextEditor(props, 'localBid');
    }

    registrationEditor(props) {
        return this.inputTextEditor(props, 'registrationDate');
    }

    requiredValidator(props) {
        let value = props.rowData[props.field];
        return value && value.length === 17;
    }

    onCurrencyChange = (event) => {
        this.props.onSelectCurrency(event.target.value);
        this.props.onSelectCurrencyModifier(this.getCurrencyAmount(event.target.value));
    };

    getCurrencyAmount = (countryCode) => {
        switch(countryCode) {
            case 'EUR':
                return 1;
            case 'CZK':
                return 25.92;
            case 'PLN':
                return 4.29;
            case 'SEK':
                return 10.2735359;
            default:
                return 1;
        }
    };

    onCountryChange = (event) => {
        if(event.target.value) {
            this.props.onSetCountryCode(event.target.value);
        }
    };

    onRemoveSearchInput = (inputNumber) => {
        let index = -1;
        this.props.searchInputs.forEach( (elem, i) => {
            if (elem.inputNumber === inputNumber) {
                index = i;
            }
        });

        if (index === -1) {
            return;
        }
        this.props.onDeleteSearchInput(index);
    };

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" icon="pi pi-trash" onClick={() => this.onRemoveSearchInput(rowData['inputNumber'])}></Button>
        </div>;
    }

    matchExact(r, str) {
        let match = str.match(r);
        return match && str === match[0];
    }

    parseLine(line) {
        // returns null if error during parsing
        const newInput = {
            inputNumber: this.props.searchInputs.length,
            vin: this.state.vinInput,
            mileage: this.state.mileageInput,
            damage: this.state.damageInput,
            localBid: this.state.localBidInput,
            registrationDate: this.state.registrationDateInput,
            mbv: '',
            brand: '',
            colorCode: '',
            listprice: 0
        };

        // use a fancy state machine like in text mining to match input
        const data = line.split(/\s/);
        const nrOfElements = data.length;

        if (nrOfElements === 0) {
            return null;
        } else if (nrOfElements > 20) {
            console.log('Error during parsing, split-by-whitespace yielded too many results. IE11 problem?');
        }

        let number_re = /\d+/;
        let vin_re = /[a-zA-Z\d]{17}/;
        let float_re = /\d+(,\d*)?/;

        let date_re_short = /\d+\.\d+\.\d\d/;
        let date_re_hypen_short = /\d+-\d+-\d\d/;
        let date_re_slash_short = /\d+\/\d+\/\d\d/;
        let date_re_long = /\d+\.\d+\.\d\d\d\d/;
        let date_re_hyphen_long = /\d+-\d+-\d\d\d\d/;
        let date_re_slash_long = /\d+\/\d+\/\d\d\d\d/;


        let elementIndex = 0;
        let element = data[elementIndex];
        if (this.matchExact(number_re, element)) {
            newInput.inputNumber = parseInt(element);
            elementIndex++;
            // next one must be the VIN
        }

        if (elementIndex >= nrOfElements) {
            return null;
        } else {
            element = data[elementIndex];
        }

        if (this.matchExact(vin_re, element)) {
            newInput.vin = element;
            elementIndex++;
        } else {
            //this might be a header or an error
            return null;
        }

        if (elementIndex >= nrOfElements) {
            //registration date is mandatory
            return null;
        } else {
            element = data[elementIndex];
        }

        // optional mileage, if not found skip
        if (this.matchExact(number_re, element)) {
            newInput.mileage = parseInt(element);
        }
        elementIndex++;

        if (elementIndex >= nrOfElements) {
            //registration date is mandatory
            return null;
        } else {
            element = data[elementIndex];
        }

        // optional damage
        if (this.matchExact(float_re, element)) {
            newInput.damage = parseFloat(element);
        }
        elementIndex++;

        if (elementIndex >= nrOfElements) {
            //registration date is mandatory
            return null;
        } else {
            element = data[elementIndex];
        }

        // optional local bid
        if (this.matchExact(float_re, element)) {
            newInput.localBid = parseFloat(element);
        }
        elementIndex++;

        if (elementIndex >= nrOfElements) {
            //registration date is mandatory
            return null;
        } else {
            element = data[elementIndex];
        }

        // mandatory registration date

        if (this.matchExact(date_re_short, element)) {
            newInput.registrationDate = this.convertTo4Digit(element, '.', true);
        } else if (this.matchExact(date_re_hypen_short, element)) {
            newInput.registrationDate = this.convertTo4Digit(element, '-', true);
        } else if (this.matchExact(date_re_slash_short, element)) {
            if (this.props.countryCode === 'FRA') {
                newInput.registrationDate = this.convertTo4Digit(element, '/', true);
            } else {
                newInput.registrationDate = this.convertTo4Digit(element, '/', false);
            }
        } else if (this.matchExact(date_re_long, element)) {
            newInput.registrationDate = element;
        } else if (this.matchExact(date_re_hyphen_long, element)) {
            newInput.registrationDate = this.format4DigitDate(element, '-', true);
        } else if (this.matchExact(date_re_slash_long, element)) {
            if (this.props.countryCode === 'FRA') {
                newInput.registrationDate = this.format4DigitDate(element, '/', true);
            } else {
                newInput.registrationDate = this.format4DigitDate(element, '/', false);
            }
        } else {
            return null;
        }

        elementIndex++;

        // if this is an extended user, more inputs are allowed
        if (this.props.isExtendedUser) {
            // mbv
            if (elementIndex < nrOfElements) {
                element = data[elementIndex];
                newInput.mbv = element;

                elementIndex++;
            }

            // brand, the comparison will fail again if it failed before
            if (elementIndex < nrOfElements) {
                element = data[elementIndex];
                newInput.brand = element;

                elementIndex++;
            }

            // color code
            if (elementIndex < nrOfElements) {
                element = data[elementIndex];
                newInput.colorCode = element;

                elementIndex++;
            }

            // listprice
            if (elementIndex < nrOfElements) {
                element = data[elementIndex];
                if (this.matchExact(float_re, element)) {
                    newInput.listprice = parseFloat(element);
                }
            }
        }

        return newInput;
    }

    convertTo4Digit = (date, separator, dayfirst) => {
        // convert everything starting from 50 to 19xx
        const parts = date.split(separator);
        const year = parseInt(parts[2]);
        if (year > 50) {
            if (dayfirst === true) {
                return parts[0] + '.' + parts[1] + '.19' + parts[2];
            } else {
                return parts[1] + '.' + parts[0] + '.19' + parts[2];
            }

        } else {
            if (dayfirst === true) {
                return parts[0] + '.' + parts[1] + '.20' + parts[2];
            } else {
                return parts[1] + '.' + parts[0] + '.20' + parts[2];
            }
        }
        // the rest is converted to 20xx
    };

    format4DigitDate = (date, separator, dayfirst) => {
        // convert everything starting from 50 to 19xx
        const parts = date.split(separator);
        if(dayfirst === true) {
            return parts[0] + '.' + parts[1] + '.' + parts[2];
        } else {
            return parts[1] + '.' + parts[0] + '.' + parts[2];
        }

    };

    onInsertFromExcel = () => {
      // when copying from clipboard, excel does not separate columns with a symbol other than whitespace
        // thus, it can be hard to see where new rows begin

        const lines = this.state.excelInput.split('\n');
        let parsedEntries = 0;
        let errorLines = 0;
        let newEntries = [];
        for (let i = 0; i < lines.length; i++) {
            let line = lines[i];
            if(line.trim() === '') {
                // ignore empty lines
                continue;
            }

            const newEntry = this.parseLine(line);
            if(!newEntry) {
                if (i === 0) {
                    // this is probably a header, that's fine
                    continue;
                } else {
                    // that is a parsing error
                    console.log('Error parsing line: ' + line);
                    errorLines++;
                }
            } else {
                newEntries.push(newEntry);
            }
            parsedEntries++;
        }

        this.props.onReplaceSearchInputs(newEntries);

        this.messages.show({ severity: 'info', summary: 'Import:', life: 8000, detail: ' Imported ' + parsedEntries + ' entries with ' + errorLines + ' errors.' });
    };

    computePrices = () => {
        // call method in App and then render PricingResult
        this.props.history.push({
            pathname: '/result'
        })
    };

    onChangeCurrencyModifier = (event) => {
        if (this.props.selectedCurrency === 'EUR') {
            this.messages.show({ severity: 'info', summary: 'Currency modifier', life: 8000, detail: 'Currency exchange rate for EUR cannot be set and must remain 1.' });
        } else {
            this.props.onSelectCurrencyModifier(event.target.value);
        }

    };

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" className="p-mr-2 p-d-inline-block" label="Add" icon="pi pi-plus" onClick={() => this.addNew()}/>
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" className="p-mr-2 p-d-inline-block" label="Delete All" icon="pi pi-trash" onClick={() => this.deleteAll()}/>
            </React.Fragment>
        )
    }


    render () {
        let datatable = null;

        if (this.props.isExtendedUser) {
            datatable =
                <DataTable value={this.props.searchInputs} style={{width: '100%'}}
                           scrollable={true} editable={true}
                           paginator={true}
                           selectionMode="single" selection={this.state.selectedEntry}
                           onSelectionChange={e => this.setState({selectedEntry: e.value})}
                           onRowSelect={this.onEntrySelect}
                           rows={20} rowsPerPageOptions={[20,50,100]}>
                    <Column body={this.actionTemplate} style={{textAlign:'center', width: '30px'}}/>
                    <Column field="inputNumber" style={{width:'100px'}} sortable={true}
                            header={<span className="js-tooltip c-tooltip" title="IDs are used to identify VINs and inputs. Necessary information (not optional)."> ID</span>} />
                    <Column field="vin" style={{width:'100px'}}
                            editor={this.vinEditor}
                            header={<span className="js-tooltip c-tooltip" title="Vehicle Identification Number (17 symbols). Needed at least for autofill.">VIN</span>} />
                    <Column field="mileage" style={{width:'100px'}}
                            editor={this.mileageEditor}
                            header={<span className="js-tooltip c-tooltip" title="Mileage of vehicle. No units or thousands separators allowed. Necessary information for IUCB price calculation.">Mileage</span>}/>
                    <Column field="damage" style={{width:'100px'}}
                            editor={this.damageEditor}
                            header={ <span className="js-tooltip c-tooltip"
                                           title="Damage of vehicle. No units or thousands separators allowed. Currency of the damage is the selected above. Look at the text-box for further information and examples. Use 0 if zero.">Damage</span>}
                    />
                    <Column field="localBid" style={{width:'100px'}}
                            editor={this.localbidEditor}
                            header={ <span className="js-tooltip c-tooltip"
                                           title="It's the highest price you can reach in your country. Not necessary for calculations. Use 0 if zero. Used to show the difference between IUCB price and your highest local bid.">Local Bid</span>}
                    />
                    <Column field="registrationDate" style={{width:'100px'}}
                            editor={this.registrationEditor}
                            header={ <span className="js-tooltip c-tooltip" title="First registration date of your vehicle. Tip: use dd-mm-yyyy format. Necessary information for IUCB price calculation.">Registration Date</span>} />

                    <Column field="mbv" style={{width:'100px'}}
                            editor={this.registrationEditor}
                            header={ <span className="js-tooltip c-tooltip" title="OPTIONAL: You can use your own MBV-Code. This is needed, if your vehicle is not in our database.">MBV</span>} />

                    <Column field="brand" style={{width:'100px'}}
                    editor={this.registrationEditor}
                    header={ <span className="js-tooltip c-tooltip" title="OPTIONAL: You can use your own Brand. This is needed, if your vehicle is not in our database.">Brand</span>} />

                    <Column field="colorCode" style={{width:'100px'}}
                    editor={this.registrationEditor}
                    header={ <span className="js-tooltip c-tooltip" title="OPTIONAL: You can use your own Color-Code. This is needed, if your vehicle is not in our database.">Color Code</span>} />

                    <Column field="listprice" style={{width:'100px'}}
                    editor={this.registrationEditor}
                    header={ <span className="js-tooltip c-tooltip" title="OPTIONAL: You can use your own List-Price. This is needed, if your vehicle is not in our database.">Listprice</span>} />
                </DataTable>

        } else {
            datatable =
                <DataTable value={this.props.searchInputs} style={{width: '100%'}}
                                           scrollable={true} editable={true}
                                           paginator={true}
                                           selectionMode="single" selection={this.state.selectedEntry}
                                           onSelectionChange={e => this.setState({selectedEntry: e.value})}
                                           onRowSelect={this.onEntrySelect}
                                           rows={20} rowsPerPageOptions={[20,50,100]}>
                <Column body={this.actionTemplate} style={{textAlign:'center', width: '30px'}}/>
                <Column field="inputNumber" style={{width:'100px'}} sortable={true}
                        header={<span className="js-tooltip c-tooltip" title="IDs are used to identify VINs and inputs. Necessary information (not optional)."> ID</span>} />
                <Column field="vin" style={{width:'100px'}}
                        editor={this.vinEditor}
                        header={<span className="js-tooltip c-tooltip" title="Vehicle Identification Number (17 symbols). Needed at least for autofill.">VIN</span>} />
                <Column field="mileage" style={{width:'100px'}}
                        editor={this.mileageEditor}
                        header={<span className="js-tooltip c-tooltip" title="Mileage of vehicle. No units or thousands separators allowed. Necessary information for IUCB price calculation.">Mileage</span>}/>
                <Column field="damage" style={{width:'100px'}}
                        editor={this.damageEditor}
                        header={ <span className="js-tooltip c-tooltip"
                                       title="Damage of vehicle. No units or thousands separators allowed. Currency of the damage is the selected above. Look at the text-box for further information and examples. Use 0 if zero.">Damage</span>}
                />
                <Column field="localBid" style={{width:'100px'}}
                        editor={this.localbidEditor}
                        header={ <span className="js-tooltip c-tooltip"
                                       title="It's the highest price you can reach in your country. Not necessary for calculations. Use 0 if zero. Used to show the difference between IUCB price and your highest local bid.">Local Bid</span>}
                />
                <Column field="registrationDate" style={{width:'100px'}}
                        editor={this.registrationEditor}
                        header={ <span className="js-tooltip c-tooltip" title="First registration date of your vehicle. Tip: use dd-mm-yyyy format. Necessary information for IUCB price calculation.">Registration Date</span>} />
            </DataTable>
        }

        const countrySelectItems = [
            {label: 'ESP', value: 'ESP'},
            {label: 'FRA', value: 'FRA'},
            {label: 'CZE', value: 'CZE'},
            {label: 'BEL', value: 'BEL'},
            {label: 'AUT', value: 'AUT'},
            {label: 'ITA', value: 'ITA'},
            {label: 'POL', value: 'POL'},
            {label: 'SVK', value: 'SVK'},
            {label: 'NDL', value: 'NDL'},
            {label: 'POR', value: 'POR'},
            {label: 'VGSG', value: 'VGSG'},
            {label: 'SWE', value: 'SWE'},
            {label: 'AKF', value: 'AKF'},
            {label: 'VDV', value: 'VDV'}
        ];

        const currencySelectItems = [
            {label: 'EUR', value: 'EUR'},
            {label: 'CZK', value: 'CZK'},
            {label: 'PLN', value: 'PLN'},
            {label: 'SEK', value: 'SEK'}
        ];


        return (
            <div>
                <nav className="c-stepper-nav o-page-wrap">
                    <ol className="c-stepper-nav__list">
                        <li className="c-stepper-nav__item  is-active">
                            <Link className="c-stepper-nav__link" to="/">Welcome!</Link>
                        </li>
                        <li className="c-stepper-nav__item  is-active">
                            <Link className="c-stepper-nav__link" to="/">Login</Link>
                        </li>
                        <li className="c-stepper-nav__item is-current">
                            <Link className="c-stepper-nav__link" to="/pricing">Enter your data</Link>
                        </li>
                        <li className="c-stepper-nav__item">
                            <Link className="c-stepper-nav__link" to="/result">Calculation and Results</Link>
                        </li>
                    </ol>
                </nav>

                <section className="o-content-section">
                    <div className="o-page-wrap">
                        <div>
                            <fieldset className="o-fieldset  u-mb">
                                <legend className="c-form-heading">
                                    <strong className="c-form-heading__title">Currency</strong>
                                </legend>

                                {this.props.isAdmin?
                                    <div className="o-fieldset__row">
                                        <div className="o-layout">
                                            <div className="o-layout__item  u-1/2  u-1/1@xs">
                                                <label htmlFor="select_currency">Please select a country:</label>
                                                <div className="c-input">
                                                    <Dropdown value={this.props.countryCode} options={countrySelectItems} onChange={(e) => this.onCountryChange(e)} placeholder="Select a Country"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                <div className="o-fieldset__row">
                                    <div className="o-layout">
                                        <div className="o-layout__item  u-1/2  u-1/1@xs">
                                            <label htmlFor="select_currency">Please specify a currency:</label>
                                            <div className="c-input">
                                                <Dropdown value={this.props.selectedCurrency} options={currencySelectItems} onChange={(e) => this.onCurrencyChange(e)} placeholder="Select a Currency"/>
                                            </div>
                                        </div>

                                        <div className="o-layout__item  u-1/2  u-1/1@xs">
                                            <label htmlFor="amount-input">Equivalent of 1&euro; in selected
                                                currency:</label>
                                            <div className="c-input">
                                                <InputText className="c-input__input" id="amount-input" type="number"
                                                           onChange={(e) => {this.onChangeCurrencyModifier(e)}} value={this.props.selectedModifier}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </fieldset>

                            <p>You can manually add new vehicles to the table by using the add button on the bottom or the excel import. The table cells are editable. Please make sure that any date formats are of type day-month-year.</p>

                            <div className="card">
                                {datatable}

                                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} ></Toolbar>
                            </div>

                            <div>
                                <p>Hint: Copy and paste your input data from Excel. Don't use currencies or thousands
                                    separators.</p>

                                <div className="p-inputgroup">
                                    <Button label="Insert from Excel" onClick={() => this.onInsertFromExcel()} />
                                    <InputTextarea id="excel-input" style={{width: '100%'}}
                                                   rows={5} cols={30}  value={this.state.excelInput}
                                                   onChange={(e) => this.setState({excelInput: e.target.value})} />
                                </div>

                                <Messages ref={(el) => this.messages = el}></Messages>
                            </div>
                            <br/>

                            {this.state.isLoading ?
                                <ProgressSpinner style={{width: '50px', height: '50px'}}/>
                                : null}
                            <Button type="button" className="p-button-success" onClick={() => this.computePrices()} disabled={this.state.isLoading}>Calculate IUCB Price</Button>


                        </div>
                    </div>
                </section>

        </div>
        );
    }

}

const mapStateToProps = (state) => {
  return {
      searchInputs: state.searchInputs,
      countryCode: state.countryCode,
      selectedCurrency: state.selectedCurrency,
      selectedModifier: state.selectedModifier,
      user: state.user,
      isAdmin: state.isAdmin,
      isExtendedUser: state.isExtendedUser
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDeleteSearchInput: (value) => dispatch({type: 'DELETE_SEARCH_INPUT', value}),
        onDeleteSearchInputs: () => dispatch({type: 'DELETE_ALL_SEARCH_INPUTS'}),
        onAddSearchInputs: (value) => dispatch({type: 'ADD_SEARCH_INPUTS', value}),
        onReplaceSearchInputs: (value) => dispatch({type: 'REPLACE_SEARCH_INPUTS', value}),
        onSetCountryCode: (value) => dispatch({type: 'SET_COUNTRY_CODE', value}),
        onSelectCurrency: (value) => dispatch({type: 'SET_CURRENCY', value}),
        onSelectCurrencyModifier: (value) => dispatch({type: 'SET_MODIFIER', value})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingSearch);
