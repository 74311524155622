import React, { Component } from 'react';
import {
    HashRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import { Auth } from "aws-amplify";

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

import Header from './container/Header/Header';
import Login from './container/Login/Login';
import ChangePassword from './container/ChangePassword/ChangePassword';

import PricingSearch from "./container/PricingSearch/PricingSearch";
import PricingResult from "./container/PricingResult/PricingResult";
import License from "./container/License/License";
import Impressum from "./container/Impressum/Impressum";
import Footer from "./container/Footer/Footer";

import UpdateAbschlagtabelle from "./container/UpdateAbschlagtabelle/UpdateAbschlagtabelle";
import UpdateFahrzeugliste from "./container/UpdateFahrzeugliste/UpdateFahrzeugliste";
import UpdateFarbabschlaege from "./container/UpdateFarbabschlaege/UpdateFarbabschlaege";
import UpdateLaenderkosten from "./container/UpdateLaenderkosten/UpdateLaenderkosten";
import UpdateMeldungen from "./container/UpdateMeldungen/UpdateMeldungen";
import UpdateMethodenwahl from "./container/UpdateMethodenwahl/UpdateMethodenwahl";
import UpdateRegelwerk from "./container/UpdateRegelwerk/UpdateRegelwerk";
import UpdateSchadenstaffel from "./container/UpdateSchadenstaffel/UpdateSchadenstaffel";
import UpdateSchadentabelle from "./container/UpdateSchadentabelle/UpdateSchadentabelle";
import UpdateStartwertkurve from "./container/UpdateStartwertkurve/UpdateStartwertkurve";

import Logs from "./container/Logs/Logs";
import {connect} from "react-redux";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticating: true,
            isLoading: false
        };

        // need to bind them for some reason even though it is an arrow function
        this.onSignIn = this.onSignIn.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    async componentDidMount() {
        // check the user session if there already is an authenticated user
        try {
            const user = await Auth.currentAuthenticatedUser();
            this.props.onSetUser(user);

            this.props.onSetIsAdmin(false);

            if (user.signInUserSession.accessToken.payload['cognito:groups']) {
                this.checkGroups(user.signInUserSession.accessToken.payload['cognito:groups']);
            }

            Auth.currentUserInfo()
                .then(result => {
                    let country_code = result.attributes['custom:country_code'];
                    if(country_code) {
                        this.props.onSetCountryCode(country_code);
                    }
                })
                .catch(err => {
                    console.log(err);
                })

        } catch(err) {
            if (err !== 'No current user') {
                this.props.onSetUser(null);
            }
        }

        this.setState({ isAuthenticating: false });
    }

    checkGroups (groups) {
        this.props.onSetUserGroups(groups);
        if(groups.indexOf('Admin_IUCB') > -1) {
            this.props.onSetIsAdmin(true);
        }
        if(groups.indexOf('Extended_User_IUCB') > -1){
            this.props.onSetIsExtendedUser(true);
        }
    }

    onLogout = () => {
        Auth.signOut()
            .then(() => {
                this.props.onSetUser(null);
                this.props.onSetIsAdmin(false);
                this.props.history.push('/');
            }).catch(err => {
            console.log(err);
        });
    };


    onSignIn = (user) => {
        this.setState({
            isLoading:false,
            isAuthenticating: false
        });
        this.props.onSetUser(user);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // a redirect is triggered from Login component
        } else {
            if (user.signInUserSession.accessToken.payload['cognito:groups']) {
                this.checkGroups(user.signInUserSession.accessToken.payload['cognito:groups']);
            }
            Auth.currentUserInfo()
                .then(result => {
                    let country_code = result.attributes['custom:country_code'];
                    if(country_code) {
                        this.props.onSetCountryCode(country_code);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };


    render() {
        // pass the login state and the callback for logging in to routes
        const childProps = {
            onSignIn: this.onSignIn,
            onLogout: this.onLogout,
            version: this.props.version
        };

        // unauthenticated routes
        let routes = (
            <Switch>
                <Route  exact path="/" render={ (props) => <Login {...props} {...childProps} />} />
                <Route  path="/login" render={ (props) => <Login {...props} {...childProps} />} />
                <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />}/>
                <Route  path="/impressum" render={ (props) => <Impressum {...props} {...childProps} />}/>
                <Route  path="/license" render={ (props) => <License {...props} {...childProps} />}/>
                <Redirect to="/" />
            </Switch>
        );

        // authenticated routes
        if (this.props.user && !this.props.isAdmin) {
            routes = (
                <Switch>
                    <Route  exact path="/" render={ (props) => <PricingSearch {...props} {...childProps} />} />
                    <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />}/>
                    <Route  path="/pricing" render={ (props) => <PricingSearch {...props} {...childProps} />} />
                    <Route  path="/result" render={ (props) => <PricingResult {...props} {...childProps} />} />
                    <Route  path="/impressum" render={ (props) => <Impressum {...props} {...childProps} />}/>
                    <Route  path="/license" render={ (props) => <License {...props} {...childProps} />}/>
                    <Redirect to="/" />
                </Switch>
            );
        }

        // authenticated admins
        if (this.props.user && this.props.isAdmin) {
            routes = (
                <Switch>
                    <Route  exact path="/" render={ (props) => <PricingSearch {...props} {...childProps} />} />
                    <Route  path="/change_password" render={ (props) => <ChangePassword {...props} {...childProps} />}/>
                    <Route  path="/pricing" render={ (props) => <PricingSearch {...props} {...childProps} />} />
                    <Route  path="/result" render={ (props) => <PricingResult {...props} {...childProps} />} />
                    <Route  path="/impressum" render={ (props) => <Impressum {...props} {...childProps} />}/>
                    <Route  path="/license" render={ (props) => <License {...props} {...childProps} />}/>

                    <Route  path="/car_update" render={ (props) => <UpdateFahrzeugliste {...props} {...childProps} />}/>
                    <Route  path="/swk_update" render={ (props) => <UpdateStartwertkurve {...props} {...childProps} />}/>
                    <Route  path="/abs_update" render={ (props) => <UpdateAbschlagtabelle {...props} {...childProps} />}/>
                    <Route  path="/staffel_update" render={ (props) => <UpdateSchadenstaffel {...props} {...childProps} />}/>
                    <Route  path="/schaden_update" render={ (props) => <UpdateSchadentabelle {...props} {...childProps} />}/>
                    <Route  path="/farb_update" render={ (props) => <UpdateFarbabschlaege {...props} {...childProps} />}/>
                    <Route  path="/regel_update" render={ (props) => <UpdateRegelwerk {...props} {...childProps} />}/>
                    <Route  path="/meldungen_update" render={ (props) => <UpdateMeldungen {...props} {...childProps} />}/>
                    <Route  path="/laender_update" render={ (props) => <UpdateLaenderkosten {...props} {...childProps} />}/>
                    <Route  path="/method_update" render={ (props) => <UpdateMethodenwahl {...props} {...childProps} />}/>

                    <Route  path="/logs" render={ (props) => <Logs {...props} {...childProps} />}/>

                    <Redirect to="/" />
                </Switch>
            );
        }

        // make sure the app does not render until the authentication state is clear!
        return ( !this.state.isAuthenticating &&
            <div className="o-root">
                <Router>
                    <div>
                        <Header {...this.props} {...childProps}/>
                        {routes}
                        <Footer {...this.props} {...childProps} />
                    </div>
                </Router>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countryCode: state.countryCode,
        user: state.user,
        userGroups: state.userGroups,
        isAdmin: state.isAdmin,
        isExtendedUser: state.isExtendedUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCountryCode: (value) => dispatch({type: 'SET_COUNTRY_CODE', value}),
        onSetUser: (value) => dispatch({type: 'SET_USER', value}),
        onSetUserGroups: (value) => dispatch({type: 'REPLACE_GROUPS', value}),
        onSetIsAdmin: (value) => dispatch({type: 'SET_IS_ADMIN', value}),
        onSetIsExtendedUser: (value) => dispatch({type: 'SET_IS_EXTENDED_USER', value})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
