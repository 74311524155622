import React from 'react'
import {Auth} from "aws-amplify";
import axios from "axios";
import {Messages} from "primereact/messages";
import {ProgressBar} from "primereact/progressbar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


class UpdateFahrzeugliste extends React.Component {
    constructor(props) {
        super(props);
        this.title = "Car Table";
        this.endpoint = process.env.REACT_APP_IUCB_UPLOAD_ENDPOINT;
        this.versionEndpoint = process.env.REACT_APP_IUCB_FAHRZEUG_VERSIONS_ENDPOINT;
        this.state = {
            isFileUploaded: false,
            dataTableUploadInProgress: false,
            dataTableUploadProgress: 0.0,
            isLoading: false
        };
    }

    componentDidMount() {
        //get the current values
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };

                axios.get(this.versionEndpoint, axiosConfig)
                    .then(result => {
                        this.setState({versions: result.data});
                    })
                    .catch (err => {
                        console.log(err);
                    });
            })
            .catch (err => {
                console.log(err);
            });
    }

    onFileChange = (files) => {
        const selectedFile = files[0];
        if (!selectedFile) {
            return;
        }

        //upload to endpoint
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };
                const params = {
                    file_identifier: 'fahrzeug_tabelle'
                };

                //retrieve pre-signed URL
                axios.post(this.endpoint, params, axiosConfig)
                    .then(result => {
                        this.setState({
                            dataTableUploadInProgress: true,
                            dataTableUploadProgress: 0.0,
                        });

                        const url = result.data.upload_url;
                        // after receiving the signed URL an upload via put is possible
                        const axiosUploadConfig = {
                            headers: {'content-disposition': 'Attachment', 'Content-Type': selectedFile.type},
                            onUploadProgress: (progressEvent) => {
                                const totalLength = progressEvent.lengthComputable ? progressEvent.total : selectedFile.size;
                                if (totalLength !== null) {
                                    this.setState({dataTableUploadProgress: Math.round((progressEvent.loaded * 100) / totalLength)});
                                }
                            }
                        };
                        axios.put(url, selectedFile, axiosUploadConfig)
                            .then(result => {
                                this.messages.show({severity: 'success', summary: 'Success', detail: 'File is completely uploaded!'});

                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0,
                                    isFileUploaded: true
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0
                                });
                                this.messages.show({severity: 'error', summary: 'Upload Error', detail: err.message});
                            });
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch (err => {
                console.log(err);
            });
    };

    render () {
        return (
            <main className="o-page-wrap">
                <h1>{this.state.title}</h1>
                <Messages ref={(el) => this.messages = el}></Messages>
                <p>Please upload a CSV file with the new values. Please make sure that the encoding is set to cp1252. This is the windows standard. All other encodings will cause errors.</p>
                { this.state.dataTableUploadInProgress ?
                    <ProgressBar value={this.state.dataTableUploadProgress}/>
                    : null
                }
                <label htmlFor="file-upload" className="c-upload"
                       data-mouseover-text="This is the mouseover variant of the upload container">
                    <input className="c-upload__input" type="file" id="file-upload" onChange={ (e) => this.onFileChange(e.target.files) } />

                    <p className="c-upload__description">
                        <span>Click on this container for file uploads</span>
                        <span>or</span>
                    </p>

                    <div className="c-upload__actions">
                        <label className="c-upload__button c-btn" htmlFor="file-upload">
                            <span>click here</span>
                        </label>
                    </div>
                </label>
                <h2>Versions</h2>
                <DataTable value={this.state.versions} style={{width: '100%'}}
                           paginator={true}
                           rows={20} rowsPerPageOptions={[20,50,100]}>
                    <Column field="filename" header="Filename" style={{width:'100px'}} />
                    <Column field="content_length" header="File Size (Bytes)" style={{width:'100px'}} />
                    <Column field="last_modified" header="Last Modified" style={{width:'100px'}} />
                </DataTable>
            </main>
        )
    }
}
export default UpdateFahrzeugliste;
