import React from "react";
import {Link} from 'react-router-dom';

class Impressum extends React.Component {
    render() {
        return (
            <main className="o-page-wrap  o-page-wrap--small">
                <div className="o-page-wrap">
                    <nav>
                        <ul className="c-breadcrumb">
                            <li>
                                <Link to="/"><i aria-hidden="true" className="c-icon c-icon--[house]"></i></Link>
                            </li>
                            <li><span>Impressum</span></li>
                        </ul>
                    </nav>
                </div>

                <div className="c-hero-teaser c-hero-teaser--no-image c-hero-teaser--auto">
                    <div className="c-hero-teaser__image"></div>
                    <div id="js-hero-teaser-content" className="c-hero-teaser__content">
                        <div className="o-page-wrap">
                            <header className="c-hero-teaser__heading">
                                <div className="c-hero-teaser__title">
                                    <h1>
                                        <span className="c-hero-teaser__title-row">Impressum</span><br/>
                                    </h1>
                                </div>

                            </header>
                        </div>
                    </div>
                </div>


                <section className="js-content-section o-content-section " id="volkswagen_bank_gmbh">
                    <div className="o-page-wrap o-page-wrap--xsmall">
                        <header className="u-text-center u-mb-large">
                            <h2 className="u-text-center">Impressum</h2>
                        </header>
                    </div>


                    <div className="o-component-wrapper">
                        <div className="o-page-wrap o-page-wrap--small">

                            <p><b>Volkswagen Bank GmbH</b><br/> Gifhorner Straße 57<br/> 38112
                                Braunschweig<br/> Telefon: +49 531 212-02<br/>
                                Telefax: +49 531 212-2275<br/> E-Mail: <a href="mailto:info@volkswagenbank.de"
                                                                          target="_self">info@volkswagenbank.de</a><br/>
                                <br/> Geschäftsführung:<br/> Dr. Michael Reinhart (Sprecher), Harald Heßke, Christian
                                Löbke, Dr. Volker Stadler
                            </p>
                            <p>Amtsgericht Braunschweig HRB 1819<br/> Umsatzsteuer-IdNr. DE 811.115.569</p>
                            <p>Aufsichtsbehörden:<br/> Europäische Zentralbank<br/> Sonnemannstraße 20<br/> 60314
                                Frankfurt am Main</p>
                            <p>Bundesanstalt für Finanzdienstleistungsaufsicht<br/> Graurheindorfer Straße
                                108<br/> 53117 Bonn<br/> und<br/>
                                Marie-Curie-Straße 24-28<br/> 60439 Frankfurt am Main</p>
                            <p>Erlaubnis nach § 34 d I GewO (Versicherungsvertreter), erteilt durch die</p>
                            <p>IHK Braunschweig<br/> Brabandtstraße 11<br/> 38100 Braunschweig</p>
                            <p>Versicherungsvermittlerregister<br/> Registernr.: D-HNQM-UQ9MO-22<br/> <a
                                href="https://www.vermittlerregister.info" target="_blank"
                                rel="noopener noreferrer"
                                data-disclaimer-ref="/configs/disclaimers/external-disclaimer.disclaimeronly.html">www.vermittlerregister.info</a>
                            </p>

                        </div>

                    </div>


                    <div className="c-divider"></div>


                </section>


                <div className="section">


                    <section className="js-content-section o-content-section " id="volkswagen_leasinggmbh">
                        <div className="o-page-wrap o-page-wrap--xsmall">

                        </div>


                        <div className="o-component-wrapper">


                            <div className="o-page-wrap o-page-wrap--small">

                                <p><b>Volkswagen Leasing GmbH</b><br/> Gifhorner Straße 57<br/> 38112
                                    Braunschweig<br/> Telefon: +49 531
                                    212-03<br/> Telefax: +49 531 212-3148<br/> E-Mail: <a
                                        href="mailto:info@volkswagenleasing.de"
                                        target="_self">info@volkswagenleasing.de</a>
                                </p>
                                <p>Geschäftsführung:<br/> Anthony Bandmann (Sprecher), Silke Finger, Knut Krösche</p>
                                <p>Amtsgericht Braunschweig HRB 1858<br/> Umsatzsteuer-IdNr. DE 811.115.317</p>
                                <p>Aufsichtsbehörde:<br/> Bundesanstalt für
                                    Finanzdienstleistungsaufsicht<br/> Graurheindorfer Straße 108<br/>
                                    53117 Bonn</p>
                                <p>Erlaubnis nach § 34 d I GewO (Versicherungsvertreter), erteilt durch die</p>
                                <p>IHK Braunschweig<br/> Brabandtstraße 11<br/> 38100 Braunschweig</p>
                                <p>Versicherungsvermittlerregister<br/> Register-Nr.: D-2C7F-QDJXU-56<br/> <a
                                    href="https://www.vermittlerregister.info" target="_blank"
                                    rel="noopener noreferrer"
                                    data-disclaimer-ref="/configs/disclaimers/external-disclaimer.disclaimeronly.html">www.vermittlerregister.info</a>
                                </p>

                            </div>

                        </div>


                        <div className="c-divider"></div>


                    </section>
                </div>


                <div className="section">


                    <section className="js-content-section o-content-section " id="volkswagen_versicherungsservice">
                        <div className="o-page-wrap o-page-wrap--xsmall">

                        </div>


                        <div className="o-component-wrapper">


                            <div className="o-page-wrap o-page-wrap--small">

                                <p><b>Volkswagen VersicherungsService <br/> Zweigniederlassung der Volkswagen
                                    Versicherungsdienst GmbH</b><br/>
                                    Gifhorner Straße 57 <br/> 38112 Braunschweig <br/> Telefon: +49 531
                                    212-859310 <br/> Telefax: +49 531
                                    212-80118 <br/> E-Mail: <a href="mailto:info@vvd.de" target="_self">info@vvd.de</a>
                                </p>
                                <p>Geschäftsführung:<br/> Anthony Bandmann, Wulf-Dieter Hartrampf</p>
                                <p>Amtsgericht Braunschweig HRB 100008<br/> Umsatzsteuer-IdNr. DE 115.239.685</p>
                                <p>Erlaubnis nach § 34 d I GewO (Versicherungsvertreter), erteilt durch die</p>
                                <p>IHK Braunschweig<br/> Brabandtstraße 11<br/> 38100 Braunschweig</p>
                                <p>Versicherungsvermittlerregister<br/> Register-Nr.: D-OWV2-6CP4Y-33<br/> <a
                                    href="https://www.vermittlerregister.info" target="_blank"
                                    rel="noopener noreferrer"
                                    data-disclaimer-ref="/configs/disclaimers/external-disclaimer.disclaimeronly.html">www.vermittlerregister.info</a>
                                </p>
                                <p></p>

                            </div>

                        </div>


                        <div className="c-divider"></div>


                    </section>
                </div>


                <div className="section">


                    <section className="js-content-section o-content-section " id="volkswagen_versicherungag">
                        <div className="o-page-wrap o-page-wrap--xsmall">

                        </div>


                        <div className="o-component-wrapper">


                            <div className="o-page-wrap o-page-wrap--small">

                                <p><b>Volkswagen Versicherung AG</b><br/> Gifhorner Straße 57<br/> 38112
                                    Braunschweig<br/> Telefon: +49 531
                                    212-5099<br/> Telefax: +49 531 212-2932<br/> E-Mail: <a
                                        href="mailto:vw_versicherung@vwfs.com"
                                        target="_self">vw_versicherung@vwfs.com</a>
                                </p>
                                <p>Vorsitzender des Aufsichtsrates:<br/> Stefan Imme<br/></p>
                                <p>Vorstand:<br/> Thorsten Krüger (Sprecher), Wulf-Dieter Hartrampf, Lars Kaufmann</p>
                                <p>Amtsgericht Braunschweig HRB 200232<br/> Steuer-Nr.:
                                    2319/200/00019<br/> Umsatzsteuer-IdNR.: DE247941282</p>
                                <p>Aufsichtsbehöre:<br/> Bundesanstalt für
                                    Finanzdienstleistungsaufsicht<br/> Graurheindorf Str. 108<br/> 53117
                                    Bonn<br/></p>

                            </div>

                        </div>


                    </section>
                </div>


            </main>
        );
    }
}

export default Impressum