import React from 'react'
import {Auth} from "aws-amplify";
import axios from "axios";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Messages} from "primereact/messages";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {ProgressBar} from "primereact/progressbar";
import {Toolbar} from "primereact/toolbar";

class UpdateSchadentabelle extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isFileUploaded: false,
            dataTableUploadInProgress: false,
            dataTableUploadProgress: 0.0,
            entries: [],
            selectedEntry: null,
            displayDialog: false,
            isLoading: false,
            versions: [],
            selectedCarType: 'kfz'
        };

        this.onEntrySelect = this.onEntrySelect.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
    }

    componentDidMount() {
        //get the current values
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };
                axios.get(process.env.REACT_APP_IUCB_SCHADENTABELLE_ENDPOINT, axiosConfig)
                    .then(result => {
                        if(result.data && result.data.length > 0) {
                            this.setState({entries: result.data});
                        }
                    })
                    .catch (err => {
                        console.log(err);
                    });
                axios.get(process.env.REACT_APP_IUCB_SCHADENTABELLE_VERSIONS_ENDPOINT, axiosConfig)
                    .then(result => {
                        this.setState({versions: result.data});
                    })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch (err => {
                console.log(err);
            });
    }

    onEntrySelect(e){
        this.newEntry = false;
        this.setState({
            displayDialog:true,
            entry: Object.assign({}, e.data)
        });
    }

    addNew = () => {
        this.newEntry = true;
        this.setState({
            entry: {countryCode: '', lower_bound:'', upper_bound: '', modifier: '', min_damage: ''},
            displayDialog: true
        });
    };

    save = () => {
        let meldungen = this.state.entries;
        if(this.newEntry)
            meldungen.push(this.state.entry);
        else
            meldungen[this.findSelectedCarIndex()] = this.state.entry;

        this.setState({entries:meldungen, selectedEntry:null, entry: null, displayDialog:false});
    };

    findSelectedCarIndex() {
        return this.state.entries.indexOf(this.state.selectedEntry);
    }

    updateProperty(property, value) {
        let car = this.state.entry;
        car[property] = value;
        this.setState({entry: car});
    }

    delete() {
        let index = this.findSelectedCarIndex();
        this.setState({
            entries: this.state.entries.filter((val, i) => i !== index),
            selectedEntry: null,
            entry: null,
            displayDialog: false});
    }

    updateValues = () => {
        //update values by JSON
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };

                const data = {
                    updated_values: this.state.entries
                };
                this.setState({isLoading: true});
                axios.post(process.env.REACT_APP_IUCB_SCHADENTABELLE_ENDPOINT, data, axiosConfig)
                    .then(result => {
                        this.messages.show({severity: 'success', summary: 'Success', detail: 'Values updated.'});
                        this.setState({isLoading: false});
                    })
                    .catch (err => {
                        this.messages.show({severity: 'error', summary: 'Upload Error', detail: err.message});
                        this.setState({isLoading: false});
                    });
            })
            .catch (err => {
                console.log(err);
            });

    };

    onFileChange = (files) => {
        const selectedFile = files[0];
        if (!selectedFile) {
            return;
        }
        //upload to endpoint
        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };
                const params = {
                    file_identifier: 'schadentabelle',
                    car_type: this.state.selectedCarType
                };

                //retrive pre-signed URL
                axios.post(process.env.REACT_APP_IUCB_UPLOAD_ENDPOINT, params, axiosConfig)
                    .then(result => {
                        const url = result.data.upload_url;
                        // after receiving the signed URL an upload via put is possible
                        const axiosUploadConfig = {
                            headers: {'content-disposition': 'Attachment', 'Content-Type': selectedFile.type},
                            onUploadProgress: (progressEvent) => {
                                const totalLength = progressEvent.lengthComputable ? progressEvent.total : selectedFile.size;
                                if (totalLength !== null) {
                                    this.setState({dataTableUploadProgress: Math.round((progressEvent.loaded * 100) / totalLength)});
                                }
                            }
                        };
                        axios.put(url, selectedFile, axiosUploadConfig)
                            .then(result => {
                                this.messages.show({severity: 'success', summary: 'Success', detail: 'File is completely uploaded!'});

                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0,
                                    isFileUploaded: true
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    dataTableUploadInProgress: false,
                                    dataTableUploadProgress: 0.0
                                });
                                this.messages.show({severity: 'error', summary: 'Upload Error', detail: err.message});
                            });
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch (err => {
                console.log(err);
            });
    };

    onCarTypeChange = (event) => {
        const carType = event.target.value;

        if(carType === 'nfz' || carType === 'kfz') {
            this.setState({selectedCarType: carType});
        }
    };

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button type="button" className="p-mr-2 p-d-inline-block" label="Add" icon="pi pi-plus" onClick={() => this.addNew()}/>
            </React.Fragment>
        )
    }

    render () {
        let paginatorLeft = <Button icon="pi pi-refresh"/>;
        let paginatorRight = <Button icon="pi pi-cloud-upload" />;

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Delete" icon="pi pi-times" onClick={() => this.delete()}/>
            <Button label="Save" icon="pi pi-check" onClick={() => this.save()}/>
        </div>;

        return (
            <main className="o-page-wrap">
                <div>
                    <h1>Damage Values</h1>
                    <p>Please use the table or the file upload function to update the values. If you provide a CSV file, please refresh the page to see the changes.</p>
                    <h2>Current Values</h2>

                    <DataTable value={this.state.entries} style={{width: '100%'}} scrollable={true}
                               paginator={true} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                               selectionMode="single" selection={this.state.selectedEntry}
                               onSelectionChange={e => this.setState({selectedEntry: e.value})}
                               onRowSelect={this.onEntrySelect}
                               rows={20} rowsPerPageOptions={[20,50,100]}>
                        <Column field="car_type" header="Car Type" style={{width:'100px'}} sortable={true} filter={true} />
                        <Column field="country_code" header="Country" style={{width:'100px'}} sortable={true} filter={true} />
                        <Column field="lower_bound" header="Lower Bound" style={{width:'100px'}} sortable={true} />
                        <Column field="upper_bound" header="Upper Bound" style={{width:'100px'}} sortable={true} />
                        <Column field="modifier" header="Modifier" style={{width:'80px'}} />
                        <Column field="min_damage" header="Min Damage" style={{width:'80px'}} />
                    </DataTable>

                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} ></Toolbar>

                    <br />
                    <button type="button" className="c-btn" onClick={ () => this.updateValues() }
                            disabled={this.state.isLoading}>
                        <span className="c-btn__text">Save and Update</span>
                        {this.state.isLoading ?
                            <div className="c-spinner c-spinner--small">
                                <svg>
                                    <circle className="c-spinner__indicator" cx="9" cy="9" r="8" fill="none"/>
                                </svg>
                            </div>
                            : null
                        }
                    </button>

                    <Messages ref={(el) => this.messages = el}></Messages>

                    <Dialog visible={this.state.displayDialog} width="300px" header="Entry Details" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.entry &&

                            <div className="p-grid p-fluid">
                                <div className="p-col-4" style={{padding:'.75em'}}><label htmlFor="car_type">Car Type</label></div>
                                <div className="p-col-8" style={{padding:'.5em'}}>
                                    <select value={this.state.entry.car_type}
                                            onChange={(e) => {this.updateProperty('car_type', e.target.value)}}>
                                        <option value="nfz">nfz</option>
                                        <option value="kfz">kfz</option>
                                    </select>
                                </div>

                                <div className="p-col-4" style={{padding:'.75em'}}><label htmlFor="country_code">Country Code</label></div>
                                <div className="p-col-8" style={{padding:'.5em'}}>
                                    <InputText id="country_code" onChange={(e) => {this.updateProperty('country_code', e.target.value)}} value={this.state.entry.countryCode}/>
                                </div>


                                <div className="p-col-4" style={{padding:'.75em'}}><label htmlFor="lower_bound">Lower Bound</label></div>
                                <div className="p-col-8" style={{padding:'.5em'}}>
                                    <InputText id="lower_bound" onChange={(e) => {this.updateProperty('lower_bound', e.target.value)}} value={this.state.entry.lower_bound}/>
                                </div>


                                <div className="p-col-4" style={{padding:'.75em'}}><label htmlFor="upper_bound">Upper Bound</label></div>
                                <div className="p-col-8" style={{padding:'.5em'}}>
                                    <InputText id="upper_bound" onChange={(e) => {this.updateProperty('upper_bound', e.target.value)}} value={this.state.entry.upper_bound}/>
                                </div>

                                <div className="p-col-4" style={{padding:'.75em'}}><label htmlFor="modifier">Modifier</label></div>
                                <div className="p-col-8" style={{padding:'.5em'}}>
                                    <InputText id="modifier" onChange={(e) => {this.updateProperty('modifier', e.target.value)}} value={this.state.entry.modifier}/>
                                </div>

                                <div className="p-col-4" style={{padding:'.75em'}}><label htmlFor="min_damage">Min Damage</label></div>
                                <div className="p-col-8" style={{padding:'.5em'}}>
                                    <InputText id="min_damage" onChange={(e) => {this.updateProperty('min_damage', e.target.value)}} value={this.state.entry.min_damage}/>
                                </div>
                            </div>
                        }
                    </Dialog>
                    <h2>Upload Values as CSV</h2>
                    <div className="o-fieldset__row">
                        <div className="o-layout">
                            <div className="o-layout__item  u-1/2  u-1/1@xs">
                                <label htmlFor="select_car_type">Please select a car type:</label>
                                <div className="c-input c-input--select">
                                    <select className="c-input__input" name="select_car_type"
                                            id="select_car_type"
                                            onChange={(e) => this.onCarTypeChange(e)}>
                                        <option value="kfz">kfz</option>
                                        <option value="nfz">nfz</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Please upload a CSV file with the same structure as the table above.</p>
                    { this.state.dataTableUploadInProgress ?
                        <ProgressBar value={this.state.dataTableUploadProgress}/>
                        : null
                    }
                    <label htmlFor="file-upload" className="c-upload"
                           data-mouseover-text="This is the mouseover variant of the upload container">
                        <input className="c-upload__input" type="file" id="file-upload" onChange={ (e) => this.onFileChange(e.target.files) } />

                        <p className="c-upload__description">
                            <span>Click on this container for file uploads</span>
                            <span>or</span>
                        </p>

                        <div className="c-upload__actions">
                            <label className="c-upload__button  c-btn" htmlFor="file-upload">
                                <span>click here</span>
                            </label>
                        </div>
                    </label>
                    <h2>Versions</h2>
                    <DataTable value={this.state.versions} style={{width: '100%'}}
                               paginator={true}
                               rows={20} rowsPerPageOptions={[20,50,100]}>
                        <Column field="filename" header="Filename" style={{width:'100px'}} />
                        <Column field="content_length" header="File Size (Bytes)" style={{width:'100px'}} />
                        <Column field="last_modified" header="Last Modified" style={{width:'100px'}} />
                    </DataTable>
                </div>
            </main>
        )
    }
}
export default UpdateSchadentabelle;
