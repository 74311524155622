import React from 'react'
import {Auth} from 'aws-amplify';
import axios from "axios";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Messages} from "primereact/messages";
import {Calendar} from 'primereact/calendar';

class Logs extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            selectedLog: null,
            isLoading: false,
            date: null
        };

        this.currencyTemplate = this.currencyTemplate.bind(this);
    }

    componentDidMount() {
        this.setState( {
            date: new Date()
        });

        this.retrieveLogs();
    }

    currencyTemplate(rowData, column) {
        if (rowData[column.field] === '') {
            return <div></div>;
        } else {
            return <div>{new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2 }).format(rowData[column.field])}</div>
        }
    }

    onEntrySelect(e){
        this.setState({
            selectedLog: Object.assign({}, e.data)
        });
    }

    retrieveLogs = () => {
        this.setState({isLoading: true});

        Auth.currentSession()
            .then(session => {
                const axiosConfig = {
                    headers: {'Authorization': session.getIdToken().getJwtToken()}
                };

                const mm = String(this.state.date.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = this.state.date.getFullYear();

                const data = {
                    reference_date: yyyy + '-' + mm
                };

                axios.post(process.env.REACT_APP_IUCB_LOG_ENDPOINT, data, axiosConfig)
                    .then(result => {
                        this.setState({logs: result.data.logs});

                        this.setState({isLoading: false});
                    })
                    .catch (err => {
                        console.log(err);
                        this.setState({isLoading: false});
                    })
            })
            .catch (err => {
                console.log(err);
                this.setState({isLoading: false});
            });
    };

    exportTable = () => {
        // this.dt.exportCSV();

        let csv = '\ufeff';

        csv += '"Username";';
        csv += '"Country";';
        csv += '"Currency";';
        csv += '"Exchange Rate";';
        csv += '"Timestamp";';
        csv += '"Pseudo-VIN";';
        csv += '"Mileage";';
        csv += '"Damage";';

        csv += '"Max Local Price";';
        csv += '"Registration";';
        csv += '"MBV";';
        csv += '"Brand";';

        csv += '"Color";';
        csv += '"IUCB";';
        csv += '"Local Price";';
        csv += '"Difference";';

        //body
        this.state.logs.forEach((record, i) => {
            csv += '\n';
            csv += '"' + record.user_name + '";';
            csv += '"' + record.country_code + '";';
            csv += '"' + record.currency + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {useGrouping: false, maximumFractionDigits: 2 }).format(record.exchange_rate) + '";';
            csv += '"' + record.timestamp + '";';
            csv += '"' + record.vin + '";';
            csv += '"' + record.mileage + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {useGrouping: false, maximumFractionDigits: 2 }).format(record.damage) + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {useGrouping: false, maximumFractionDigits: 2 }).format(record.max_price_local) + '";';
            csv += '"' + record.registration_date + '";';
            csv += '"' + record.mbv + '";';
            csv += '"' + record.brand + '";';
            csv += '"' + record.color + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {useGrouping: false, maximumFractionDigits: 2 }).format(record.iucb) + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {useGrouping: false, maximumFractionDigits: 2 }).format(record.Local) + '";';
            csv += '"' + new Intl.NumberFormat('de-DE', {useGrouping: false, maximumFractionDigits: 2 }).format(record.diff) + '";';
        });

        let blob = new Blob([csv],{
            type: 'text/csv;charset=utf-8;'
        });

        // admin IUCB Price Indication Results 11-04-2019 1401
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        const hours = today.getHours();
        const minutes = today.getMinutes();

        const filename = 'Logfile_Price-Indication-Results_' + dd + '_' + mm + '_' + yyyy + '_' + hours + '_' + minutes + '.csv';

        if(window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        }
        else {
            let link = document.createElement("a");
            link.style.display = 'none';
            document.body.appendChild(link);
            if(link.download !== undefined) {
                link.setAttribute('href', URL.createObjectURL(blob));
                link.setAttribute('download', filename);
                link.click();
            }
            else {
                csv = 'data:text/csv;charset=utf-8,' + csv;
                window.open(encodeURI(csv));
            }
            document.body.removeChild(link);
        }
    };

    render () {
        return (
            <main className="o-page-wrap">
                <div>
                    <h1>Logs</h1>
                    <fieldset className="o-fieldset  u-mb">
                            <div className="o-fieldset__row">
                                <div className="o-layout">
                                    <div className="o-layout__item  u-1/2  u-1/1@xs">
                                        <label htmlFor="select_currency">Please select a month and year:</label>
                                        <div className="c-input">
                                            <Calendar value={this.state.date} onChange={(e) => this.setState({date: e.value})} view="month" dateFormat="yy-mm" yearNavigator={true} yearRange="2018:2025"/>
                                        </div>
                                    </div>

                                    <div className="o-layout__item  u-1/2  u-1/1@xs">
                                        <button className="c-btn" onClick={() => this.retrieveLogs()}
                                                disabled={this.state.isLoading}>
                                            <span className="c-btn__text">Retrieve Logs</span>
                                            {this.state.isLoading ?
                                                <div className="c-spinner c-spinner--small">
                                                    <svg>
                                                        <circle className="c-spinner__indicator" cx="9"
                                                                cy="9"
                                                                r="8"
                                                                fill="none"/>
                                                    </svg>
                                                </div>
                                                : null}
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </fieldset>

                    <br />

                    <button type="button" style={{marginBottom: '3px'}} className="c-btn  c-btn--icon" onClick={this.exportTable}>
                        <span className="c-btn__text">Export Table</span>
                        <i className="c-btn__icon  c-icon  c-icon--[control-download]"></i>
                    </button>
                    <br />
                    <DataTable value={this.state.logs} style={{width: '100%'}} scrollable={true}
                               paginator={true} ref={(el) => { this.dt = el; }}
                               csvSeparator={";"}
                               selectionMode="single" selection={this.state.selectedEntry}
                               onSelectionChange={e => this.setState({selectedEntry: e.value})}
                               onRowSelect={this.onEntrySelect}
                               rows={20} rowsPerPageOptions={[20,50,100]}>
                        <Column field="user_name" header="Username" sortable={true} style={{width:'100px'}}/>
                        <Column field="country_code" header="Country" sortable={true} style={{width:'100px'}}/>
                        <Column field="currency" header="Currency" style={{width:'80px'}}/>
                        <Column field="exchange_rate" header="Exchange Rate" style={{width:'100px'}}/>
                        <Column field="timestamp" header="Timestamp" sortable={true} style={{width:'100px'}}/>
                        <Column field="vin" header="Pseudo-VIN" style={{width:'200px'}}/>
                        <Column field="mileage" header="Mileage" style={{width:'100px'}}/>
                        <Column field="damage" header="Damage" style={{width:'100px'}} body={this.currencyTemplate} />
                        <Column field="max_price_local" header="Max Local Price" style={{width:'120px'}} body={this.currencyTemplate}/>

                        <Column field="registration_date" header="Registration" style={{width:'100px'}}/>
                        <Column field="mbv" header="MBV" style={{width:'80px'}}/>
                        <Column field="brand" header="Brand" style={{width:'100px'}}/>

                        <Column field="color" header="Color" style={{width:'80px'}}/>
                        <Column field="iucb" header="IUCB" style={{width:'100px'}} body={this.currencyTemplate}/>
                        <Column field="Local" header="Local Price" style={{width:'100px'}} body={this.currencyTemplate}/>
                        <Column field="diff" header="Difference" style={{width:'100px'}} body={this.currencyTemplate}/>
                    </DataTable>

                    <Messages ref={(el) => this.messages = el}></Messages>
                </div>
            </main>
        )
    }
}
export default Logs;